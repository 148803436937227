import PFP from "../assets/me.jpg";

export const title = "Looking to get in touch?";
export const text =
  "You can easily get in touch with me through Instagram, LinkedIn, or Email; don't hesitate to connect on those platforms. For a more direct approach, you can simply fill out the form, and I'll get back to you promptly. I'd be excited to discuss how we can collaborate on your front-end web development needs!";

export const contactDetails = {
  email: "mailto:jtayped@gmail.com",
  instagram: {
    link: "https://www.instagram.com/jtayped_/",
    username: "jtayped_",
    name: "Joel Taylor",
    pfp: PFP,
    posts: 0,
    followers: 354,
    following: 367,
  },
  linkedIn: {
    link: "https://www.linkedin.com/in/joel-taylor-065bb8263/",
    name: "Joel Taylor",
    pfp: PFP,
    location: "Mollerussa, Catalonia, Spain",
    school: "La Salle Mollerussa",
    followers: 4,
    connections: 4,
  },
};
