import React, { useEffect, useState } from "react";
import { TDRSection } from "../components";
import Posts from "../assets/TDR/posts.json";

const TDR = () => {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    setPosts(Posts.posts);
  }, []);
  return (
    <div className="w-full flex justify-center pt-[100px]">
      <main className="max-w-[800px] mb-10 px-5">
        <div className="flex flex-col items-center gap-6">
          <div className="text-center max-w-[550px]">
            <h1 className="text-4xl font-poppins font-semibold">
              Learn more about SEO!
            </h1>
            <p>
              Delve into SEO literature review insights—discover strategies to
              boost online visibility without the marketing hype. Knowledge for
              the curious mind.
            </p>
          </div>
          <ol className="flex flex-col gap-3 w-full">
            {posts.map((post, index) => (
              <li key={index}>
                <TDRSection {...post} index={index} />
              </li>
            ))}
          </ol>
        </div>
      </main>
    </div>
  );
};

export default TDR;
