// React Util
import React, { useState } from "react";
import { Helmet } from "react-helmet";

// JSX Elements
import {
  Hero,
  About,
  Testimonials,
  Portfolio,
  Contact,
  Popup,
} from "../containers";

// CSS
import "./anchorSpacing.css";

// Animations
import { motion, AnimatePresence } from "framer-motion";

const Home = () => {
  const [popup, setPopup] = useState(true);

  function togglePopup() {
    setPopup(!popup);
  }

  return (
    <div className="font-poppins overflow-x-hidden">
      <Helmet>
        <meta
          name="description"
          content="Looking for a skilled web developer? Check out my portfolio! I'm Joel Taylor Pedrós, and I specialize in creating websites that stand out from the crowd. Click now to see my work!"
        />
        <meta name="author" content="Joel Taylor Pedrós" />
        <meta
          name="keywords"
          content="Web, Developer, Modern, New, Minimalistic, Designer, Custom, React, HTML, CSS, JS, Joel, Taylor"
        />
        <meta name="og:title" content="Joel Taylor Pedrós | Web Developer" />
        <meta
          name="og:description"
          content="Looking for a skilled web developer? Check out my portfolio! I'm Joel Taylor Pedrós, and I specialize in creating websites that stand out from the crowd. Click now to see my work!"
        />
        <meta name="og:image" content="%PUBLIC_URL%/logo.png" />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="%PUBLIC_URL%/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="%PUBLIC_URL%/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="%PUBLIC_URL%/favicon-16x16.png"
        />
        <link rel="manifest" href="%PUBLIC_URL%/site.webmanifest" />

        <title>Joel Taylor Pedrós | Web Developer</title>
      </Helmet>
      <AnimatePresence>
        {popup ? (
          <motion.div exit={{ opacity: 0 }} transition={{ duration: 0.01 }}>
            <Popup togglePopup={togglePopup} />
          </motion.div>
        ) : null}
      </AnimatePresence>

      <Hero />
      <About />
      <Testimonials />
      <Portfolio />
      <Contact />
    </div>
  );
};

export default Home;
