import React, { useEffect, useState } from "react";
import { convertToUrl } from "../api";

const MiniBlogPostPreview = ({ post }) => {
  const [url, setUrl] = useState("");
  useEffect(() => {
    setUrl(convertToUrl(post.title));
  }, [post.title]);

  return (
    <a href={`/#/blog/${url}`} className="flex gap-2">
      <img
        className="h-[60px] w-[80px] object-cover self-center rounded"
        src={post.cover}
        alt="Blog post preview cover"
      />
      <div className="flex flex-col">
        <p className="line-clamp-2 leading-tight">{post.title}</p>
        <p className="text-xs text-gray-500">{post.date}</p>
      </div>
    </a>
  );
};

export default MiniBlogPostPreview;
