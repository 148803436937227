import React from "react";
import { Link } from "react-router-dom";
import { GiIncomingRocket } from "react-icons/gi";

const Development = () => {
  return (
    <div className="h-screen flex items-center justify-center px-5">
      <div className="font-poppins flex flex-col items-center text-center max-w-[450px]">
        <GiIncomingRocket size={200} />
        <h1 className="text-5xl font-black mt-10">Coming Soon!</h1>
        <p className="mt-3">
          This page is currently undergoing development. Stay tuned for
          something great! In the meantime, feel free to visit our{" "}
          <Link to="/" className="underline underline-offset-2 font-semibold">
            home page
          </Link>{" "}
          for more information and updates.
        </p>
      </div>
    </div>
  );
};

export default Development;
