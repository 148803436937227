export const pages = [
  {
    title: "Blog",
    link: "/blog",
  },
  {
    title: "TDR",
    link: "/tdr",
  },
  {
    title: "About",
    link: "/about",
  },
  {
    title: "Portfolio",
    link: "/portfolio",
  },
  {
    title: "Contact",
    link: "/contact",
  },
];
