import React from "react";
import { AiOutlineSearch } from "react-icons/ai";

const BlogSearchBar = ({ setSearchQuery }) => {
  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <div className="flex items-center gap-2 w-full p-2 rounded-lg border border-black/20 bg-slate-50">
      <AiOutlineSearch size={20} className="text-black/50" />
      <input
        type="text"
        placeholder="Search"
        className="placeholder:text-black/50 w-full outline-none bg-transparent"
        onChange={handleInputChange} // Add onChange handler
      />
    </div>
  );
};

export default BlogSearchBar;
