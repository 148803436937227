// React Util
import React from "react";
import { Routes, Route } from "react-router-dom";

// JSX Elements
import { Header } from "./containers";
import { Home, Blog, BlogPost, NotFound, Development, TDR } from "./pages";

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/blog" element={<Blog />} />
        <Route exact path="/about" element={<Development />} />
        <Route exact path="/portfolio" element={<Development />} />
        <Route exact path="/contact" element={<Development />} />
        <Route exact path="/tdr" element={<TDR />} />
        <Route exact path="/tdr/:urlTitle" element={<BlogPost />} />
        <Route exact path="/blog/:urlTitle" element={<BlogPost />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default App;
