import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { convertToUrl } from "../api";

const TDRSection = ({ cover, description, title, index }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    setUrl(convertToUrl(title));
  }, [title]);

  return (
    <div className="bg-white border rounded-lg flex flex-col sm:flex-row overflow-hidden shadow">
      <img
        src={cover}
        alt="Blog cover"
        className="object-cover flex-shrink-0 w-full sm:h-auto h-[100px] sm:w-[200px]"
      />
      <div className="flex-grow p-4">
        <h2 className="text-lg sm:text-xl font-poppins font-bold">{title}</h2>
        <p className="text-sm mb-1">{description}</p>
        <Link
          to={`/tdr/${url}`}
          className="bg-black px-2 py-0.5 text-white font-poppins text-sm rounded"
        >
          Read More
        </Link>
      </div>
    </div>
  );
};

export default TDRSection;
