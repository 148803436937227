import React from "react";
import { IoChevronForward } from "react-icons/io5";

const PageSelector = ({ page, setPage, totalPages }) => {
  function nextPage() {
    if (page < totalPages) {
      setPage(page + 1);
    }
  }
  return (
    <div className="flex justify-center items-center">
      <ol className="flex items-center gap-1">
        {Array.apply(null, { length: totalPages }).map((e, i) => (
          <li
            className={`flex items-center justify-center border border-zinc-900 shadow ${
              page === i + 1 ? "bg-zinc-900 text-white" : null
            }`}
            key={i}
          >
            <button onClick={() => setPage(i + 1)} className="w-8 h-8">
              {i + 1}
            </button>
          </li>
        ))}
        <li className="border shadow border-zinc-900">
          <button
            onClick={() => nextPage()}
            className="h-8 px-2 flex items-center gap-0.5"
          >
            Next
            <IoChevronForward />
          </button>
        </li>
      </ol>
    </div>
  );
};

export default PageSelector;
