import React from "react";
import PopupIMG from "../assets/popup/popup.jpg";
import { Link } from "react-router-dom";
import { BsBookFill } from "react-icons/bs";
import { BsXCircle } from "react-icons/bs";

const Popup = ({ togglePopup }) => {
  return (
    <div className="fixed bottom-0 right-0 z-[100] p-5">
      {" "}
      <div className="bg-white sm:grid grid-cols-2 rounded-lg overflow-hidden max-w-[400px] sm:max-w-[550px] border border-black/20 shadow-lg">
        <div className="p-5 flex flex-col justify-between">
          <div>
            <p className="bg-black w-fit text-xs text-white px-1 py-0.5 rounded">
              NEW PROJECT
            </p>
            <p className="text-2xl font-semibold mt-1 leading-tight">
              Check Out My Research Project!
            </p>
            <p className="text-sm mb-2">
              Uncover transformative SEO strategies. Dive into website
              optimization with React JS and Tailwind CSS. Elevate visibility,
              triple clicks, and soar in SERPs.
            </p>
          </div>
          <Link
            to="/tdr"
            className="bg-black px-2 py-1 text-sm text-white rounded flex items-center justify-center gap-2 w-fit"
          >
            <BsBookFill />
            Start Reading
          </Link>
        </div>
        <img
          className="w-[300px] h-full object-cover object-left hidden sm:flex"
          src={PopupIMG}
          width={400}
          height={400}
          alt="Laptop with search metrics"
        />
      </div>
      <button
        className="absolute top-7 right-7 m-3"
        onClick={() => togglePopup()}
      >
        <BsXCircle size={20} />
      </button>
    </div>
  );
};

export default Popup;
