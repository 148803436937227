import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="h-screen flex items-center justify-center">
      <div className="font-poppins flex flex-col items-center text-center max-w-[500px]">
        <h1 className="text-8xl font-bold">404</h1>
        <h2 className="text-2xl tracking-[6px]">Not Found</h2>
        <p className="mt-5">
          Oops! It seems the page you're looking for doesn't exist. Why not
          return to my{" "}
          <Link
            to="/"
            className="underline underline-offset-2 font-semibold"
          >
            portfolio
          </Link>{" "}
          and have a look at my projects?
        </p>
      </div>
    </div>
  );
};

export default NotFound;
