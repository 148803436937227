// Images
import BG1 from "../assets/hero/bg1.webp";
import BG2 from "../assets/hero/bg2.webp";

export const title = "Welcome";
export const text =
  "Welcome to my portfolio. I'm a front-end developer, specializing in stunning and user-friendly websites with React, HTML, CSS, and JS. Let's craft a captivating web experience together.";

export const sections = [
  {
    title: "Boost Your Business Online",
    image: BG1,
    text: "Elevate your online presence with my front-end web solutions. I design captivating, user-friendly websites that attract and engage your audience, boosting brand visibility and conversion rates.",
  },
  {
    title: "Unleash the Potential of the Web",
    image: BG2,
    text: "Unlock Web Potential with a Front-End Developer. Maximize Audience Engagement and Growth with Customized Solutions.",
  },
];
