// Portfolio
import {
  GPTArticles,
  Logitech,
  SpotifyConcept,
  Bitcoin,
} from "../assets/portfolio";

export const title = "Portfolio";
export const projects = [
  {
    title: "Bitcoin Website",
    text: "Explore my web development skills with a Bitcoin-themed site providing real-time cryptocurrency data through an API.",
    image: Bitcoin,
    link: "https://bitcoin-website-zeta.vercel.app/",
  },
  {
    title: "Logitech M170",
    text: "Discover Logitech's M170 Wireless Mouse through an engaging digital journey, highlighting its features and design.",
    image: Logitech,
    link: "https://logitech-mouse.vercel.app/",
  },
  {
    title: "Spotify Concept",
    text: "[Not supported anymore] Experience music discovery on a visually stunning Spotify Concept website. Connect, explore, and enjoy your favorite tracks and playlists with ease.",
    image: SpotifyConcept,
    link: "/",
  },
  {
    title: "GPT Articles",
    text: "Welcome to GPT Articles, a humorous twist on AI-generated content. Get ready to laugh and be entertained as AI humor takes over the world of information.",
    image: GPTArticles,
    link: "https://gpt-articles-tan.vercel.app/",
  },
];
