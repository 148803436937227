import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getPosts, convertToUrl } from "../api";
import { MiniBlogPostPreview } from "../components";
import { Helmet } from "react-helmet";
import Posts from "../assets/blog/posts.json";

const BlogPost = () => {
  const { urlTitle } = useParams();
  const [posts, setPosts] = useState([]);
  const [post, setPost] = useState(null);
  const [postLoading, setPostLoading] = useState(true);

  useEffect(() => {
    function shuffle(array) {
      let currentIndex = array.length,
        randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex > 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex],
          array[currentIndex],
        ];
      }

      return array;
    }

    getPosts(Posts.posts).then((posts) => {
      // Find page post
      const matchingPost = posts.find(
        (post) => convertToUrl(post.title) === urlTitle
      );

      if (matchingPost) {
        setPost(matchingPost);
        document.title = matchingPost.title;
      }

      // Filter out the matching post from the posts array
      const updatedPosts = posts.filter(
        (post) => convertToUrl(post.title) !== urlTitle
      );

      setPosts(shuffle(updatedPosts));
      setPostLoading(false);
    });
  }, [urlTitle]);

  return (
    <div className="font-sanserif overflow-x-hidden px-5 flex items-center justify-center">
      {postLoading ? null : post ? (
        <div className="flex gap-10 pt-20 pb-5">
          <main className="prose max-w-prose mx-auto prose-pre:bg-slate-300 prose-code:text-black prose-pre:border prose-pre:shadow">
            <Helmet>
              <title>{post.title}</title>
              <meta property="og:title" content={post.title} />
              <meta name="description" content={post.description} />
              <meta name="keywords" content={post.keywords} />
              <meta name="author" content={post.author} />
              <meta
                property="article:published_time"
                content={post.publishedDate}
              />
              <meta property="og:description" content={post.description} />
              <meta property="og:image" content={post.cover} />
              <meta property="og:url" content={window.location.href} />
              <meta property="og:type" content="article" />
              <meta name="twitter:card" content={post.cover} />
            </Helmet>
            <img
              src={post.cover}
              className="rounded h-[200px] md:h-[360px] w-full object-cover"
              alt="Article Cover"
            />
            <h1>{post.title}</h1>
            <article className="">
              <div dangerouslySetInnerHTML={{ __html: post.htmlContent }} />
            </article>
          </main>
          <aside className="hidden lg:flex max-w-[300px] flex-col gap-7">
            <ul className="flex flex-col gap-5">
              {posts.slice(0, 4).map((post, index) => (
                <li key={index}>
                  <MiniBlogPostPreview post={post} />
                </li>
              ))}
            </ul>
          </aside>
        </div>
      ) : (
        <div className="h-screen flex items-center justify-center">
          <div className="font-poppins flex flex-col items-center text-center max-w-[500px]">
            <h1 className="text-8xl font-bold">404</h1>
            <h2 className="text-2xl tracking-[6px]">Not Found</h2>
            <p className="mt-5">
              Oops! It seems the page you're looking for doesn't exist. Why not
              return to our{" "}
              <Link
                to="/blog"
                className="underline underline-offset-2 font-semibold"
              >
                blog
              </Link>{" "}
              and explore more exciting content?
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogPost;
