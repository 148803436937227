import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { BlogPostPreview, BlogSearchBar, PageSelector } from "../components";
import Posts from "../assets/blog/posts.json";

const postsPerPage = 9;
const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [searchQuery, setSearchQuery] = useState(""); // State to track the search query
  const [filteredPosts, setFilteredPosts] = useState([]); // State to store filtered posts
  const [page, setPage] = useState(1);

  useEffect(() => {
    setPosts(postsPerPage * (page - 1), postsPerPage * page);
  }, [page]);

  useEffect(() => {
    // Update filteredPosts whenever searchQuery or posts change
    const filtered = Posts.posts.filter((post) => {
      const titleMatch = post.title
        .toLowerCase()
        .includes(searchQuery.toLowerCase());
      const keywordMatch = post.keywords.some((keyword) =>
        keyword.toLowerCase().includes(searchQuery.toLowerCase())
      );
      return titleMatch || keywordMatch;
    });
    setFilteredPosts(filtered);

    const nPages = Math.ceil(filteredPosts.length / postsPerPage);
    console.log(nPages);
    if (nPages > 0 && page > nPages) {
      setPage(nPages);
      console.log(nPages, page);
    }
  }, [page, filteredPosts.length, searchQuery, posts]);

  return (
    <div className="font-poppins overflow-x-hidden pt-20 px-5 flex items-center justify-center">
      <Helmet>
        <title>Joel Taylor Pedrós | Blog</title>
        <meta property="og:title" content="Joel Taylor | Blog" />
        <meta
          name="description"
          content="Welcome to my portfolio blog, where creativity and inspiration meet. Explore my work and join me on this creative journey!"
        />
        <meta
          name="keywords"
          content="blog,joel taylor,mollerussa,articles,modern articles,blog posts,technology"
        />
        <meta name="author" content="Joel Taylor Pedrós" />
        <meta
          property="og:description"
          content="Welcome to my portfolio blog, where creativity and inspiration meet. Explore my work and join me on this creative journey!"
        />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="flex flex-col gap-10 max-w-[800px] mt-7 md:mt-14">
        <div className="w-full flex justify-center">
          <dir className="w-full text-center max-w-[600px]">
            <h1 className="text-3xl">Welcome to my blog!</h1>
            <p>
              Welcome to my{" "}
              <Link className="underline underline-offset-2" to="/">
                portfolio
              </Link>{" "}
              blog, where creativity and inspiration meet. Explore my work and
              join me on this creative journey!
            </p>
          </dir>
        </div>

        <div className="flex flex-col gap-3">
          <div className="md:w-[800px] w-full">
            <BlogSearchBar setSearchQuery={setSearchQuery} />
          </div>

          <main className="">
            <ul className="grid grid-cols-2 md:grid-cols-3 gap-2 w-full">
              {filteredPosts
                .slice(postsPerPage * (page - 1), postsPerPage * page)
                .map((post, index) => (
                  <li key={index}>
                    <BlogPostPreview post={post} />
                  </li>
                ))}
            </ul>
            <div className="px-2 py-6">
              {filteredPosts.length > 0 ? (
                <PageSelector
                  page={page}
                  setPage={setPage}
                  totalPages={Math.ceil(filteredPosts.length / postsPerPage)}
                />
              ) : null}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Blog;
