import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Me from "../assets/me.jpg";
import { AiFillRead } from "react-icons/ai";

const BlogPostPreview = ({ post }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    function convertToUrl(title) {
      return title
        .replace(/[^\w\s]/g, "")
        .replace(/\s+/g, "-")
        .toLowerCase();
    }

    setUrl(convertToUrl(post.title));
  }, [post.title]);
  return (
    <div className="flex flex-col justify-between gap-3 bg-slate-50 p-3 border border-black/10 rounded-lg h-full font-sanserif">
      <div className="flex flex-col gap-2">
        <Link to={`/blog/${url}`}>
          <img
            className="w-full h-[100px] sm:h-[145px] object-cover rounded"
            src={post.cover}
            alt="Blog cover"
          />
        </Link>
        <div className="flex items-center gap-2 text-xs mt-0.5">
          <img
            src={Me}
            alt="Author"
            className="rounded-full w-8 aspect-square"
          />
          <div className="flex flex-col">
            <p>{post.author}</p>
            <p className="text-gray-400">{post.date}</p>
          </div>
        </div>
        <div>
          <h2 className="text-sm font-bold leading-tight">{post.title}</h2>
          <p className="text-xs text-black/90 font-sanserif line-clamp-[8]">
            {post.description}
          </p>
        </div>
      </div>
      <Link
        to={`/blog/${url}`}
        className="text-sm border border-black/30 hover:bg-black hover:text-white transition-colors duration-75 px-1 py-0.5 rounded flex justify-center items-center gap-1"
      >
        <AiFillRead />
        Read more
      </Link>
    </div>
  );
};

export default BlogPostPreview;
