import axios from "axios";
import { remark } from "remark";
import remarkHtml from "remark-html";

export function convertToUrl(title) {
  return title
    .replace(/[^\w\s]/g, "")
    .replace(/\s+/g, "-")
    .toLowerCase();
}

export async function getPosts(posts) {
  try {
    const postPromises = posts.map(async (post) => {
      const module = await import(`./assets${post.path}`);
      const content = module.default;

      const response = await axios.get(content);
      const markdownContent = response.data;

      const htmlContent = remark()
        .use(remarkHtml)
        .processSync(markdownContent)
        .toString();

      return {
        ...post,
        htmlContent,
      };
    });
    const parsedPosts = await Promise.all(postPromises);
    return parsedPosts;
  } catch (error) {
    console.error("Error loading posts:", error);
    throw error; // You can throw the error or handle it as needed
  }
}

export async function extractMetadata(markdown) {
  try {
    const metadataRegex = /^--([\s\S]*?)--/; // Updated regex to handle optional newlines
    const metadataMatch = markdown.match(metadataRegex);

    if (metadataMatch) {
      const metadataLines = metadataMatch[1]
        .split("\n")
        .filter((line) => line.trim() !== "");
      const metadata = {};

      metadataLines.forEach((line) => {
        const [key, value] = line.split(/:(.+)/).map((str) => str.trim());
        if (key && value) {
          // Check if the key is "tags" and handle it as an array
          if (key.toLowerCase() === "tags") {
            metadata[key] = value.split(",").map((tag) => tag.trim());
          } else {
            metadata[key] = value;
          }
        }
      });

      // Remove metadata lines from the markdown content
      const updatedMarkdown = markdown.replace(metadataRegex, "");

      return { metadata, updatedMarkdown };
    } else {
      throw new Error("Metadata not found in the Markdown file.");
    }
  } catch (error) {
    console.error("Error extracting metadata:", error);
    throw error;
  }
}

export async function parseMarkdown(markdown) {
  try {
    // Use remark to parse Markdown to HTML
    const htmlContent = await remark().use(remarkHtml).process(markdown);

    // The resulting HTML is in htmlContent.contents
    return htmlContent.contents;
  } catch (error) {
    console.error("Error parsing post:", error);
    throw error;
  }
}
